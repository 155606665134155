var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "d-flex" },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto mt-2", attrs: { "max-width": "600px" } },
        [
          _c("v-card-title", [_vm._v("\n      Refund Eligibility\n    ")]),
          _c(
            "v-card-text",
            [
              _vm.loading
                ? _c("loading", { attrs: { loading: _vm.loading } })
                : _c("div", [
                    !_vm.$store.getters.isSubscribed
                      ? _c("div", [
                          _vm._v(
                            "\n          Your account is not subscribed, so you are not eligible for a refund.\n        "
                          )
                        ])
                      : _vm.isRefundEligible
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                "To request a refund for the subscribed account " +
                                  _vm.$store.state.account.accountId +
                                  ", please email refunds@truple.io from " +
                                  _vm.$store.state.account.accountId +
                                  " requesting the refund."
                              ) +
                              "\n        "
                          )
                        ])
                      : _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                "Your subscription started on " +
                                  _vm.getSubscriptionStartDate +
                                  " and your refund eligibility expired on " +
                                  _vm.getEligibleUntilDate +
                                  ".  You cannot receive a refund."
                              ) +
                              "\n        "
                          )
                        ])
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }