<template>
  <v-container class="d-flex">
    <v-card max-width="600px" class="mx-auto mt-2">
      <v-card-title>
        Refund Eligibility
      </v-card-title>
      <v-card-text>
        <loading :loading="loading" v-if="loading" />
        <div v-else>
          <div v-if="!$store.getters.isSubscribed">
            Your account is not subscribed, so you are not eligible for a refund.
          </div>
          <div v-else-if="isRefundEligible">
            {{`To request a refund for the subscribed account ${$store.state.account.accountId}, please email refunds@truple.io from ${$store.state.account.accountId} requesting the refund.`}}
          </div>
          <div v-else>
            {{`Your subscription started on ${getSubscriptionStartDate} and your refund eligibility expired on ${getEligibleUntilDate}.  You cannot receive a refund.`}}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

const moneyBackGuaranteeThreshold = 30 * 24 * 60 * 60 * 1000

import Loading from "@/components/Loading";
export default {
  name: "Refund",
  components: {Loading},
  data() {
    return {
      loading: true
    }
  },
  methods: {
    async init() {
      await this.$store.state.accountLoadedPromise
      this.loading = false
    }
  },
  computed: {
    getSubscriptionStartDate() {
      const state = this.$store.state
      if(!state.account || !state.account.account || !state.account.account.accountSettings || !state.account.account.accountSettings || !state.account.account.accountSettings.startDate) {
        return null
      } else {
        return (new Date(state.account.account.accountSettings.startDate)).toLocaleString()
      }
    },
    isRefundEligible () {
      const state = this.$store.state
      if(!state.account || !state.account.account || !state.account.account.accountSettings || !state.account.account.accountSettings || !state.account.account.accountSettings.startDate) {
        return false
      } else {
        // return (Date.now() - (30 * 24 * 60 * 60 * 1000)) > state.account.account.accountSettings.startDate
        return Date.now() - state.account.account.accountSettings.startDate < moneyBackGuaranteeThreshold
      }
    },
    getEligibleUntilDate() {
      const state = this.$store.state
      if(!state.account || !state.account.account || !state.account.account.accountSettings || !state.account.account.accountSettings || !state.account.account.accountSettings.startDate) {
        return null
      } else {
        return (new Date(state.account.account.accountSettings.startDate + moneyBackGuaranteeThreshold)).toLocaleString()
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped>

</style>